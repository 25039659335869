import {
  type RegistrationJournalGroup as JournalGroup,
  createJournalGroup,
} from "../models/journalGroup";
import type { Registration } from "../models/registration";
import { ApiBase } from "./apiBase";

class RegistrationApi extends ApiBase {
  async find(labnumber: number) {
    return this.get<number>("preregistration/find", { labnumber });
  }
  async fetchPreregistration(registrationId: number) {
    return this.get<Registration>("preregistration/fetch", { registrationId });
  }
  async update(journalGroup: JournalGroup) {
    return createJournalGroup(
      await this.post<JournalGroup>("registration/update", {
        journalGroup: journalGroup,
      }),
    );
  }
  async fetchAutofill(path: string) {
    return this.get<string[]>(path);
  }
}

export default new RegistrationApi();
