// import type { AnalysisNg } from "./analysis";
// import type { Bubble } from "./bubble";
// import type { Message } from "./message";

export interface AnalysisGroupInstance {
  // id?: number;
  // code?: string;
  // analyses?: AnalysisNg[];
  // bubble?: Bubble;
  // messages?: Message[];
  // externalNote?: string;
  // savedAt?: string;
  // savedBy?: string;
  // readBy?: string;
  // scannedAt?: string;
  selected?: boolean;
  groupnr?: number;
  analysisGroupId: number;
  invoice?: boolean;
}

const defaultAnalysisGroupInstance = {
  selected: true,
};

export function createAnalysisGroupInstance(
  analysisGroupInstance: AnalysisGroupInstance,
) {
  return { ...defaultAnalysisGroupInstance, ...analysisGroupInstance };
}
