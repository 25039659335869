import { combineReducers } from "@reduxjs/toolkit";
import agrilab from "../features/agrilabSlice";
import dispose from "../features/disposalSlice";
import godInvoice from "../features/godInvoiceSlice";
import check from "../features/lineCheckSlice";
import notifications from "../features/notificationSlice";
import pause from "../features/pauseSlice";
import preregistration from "../features/preregistrationSlice";
import registration from "../features/registrationSlice";
import bubbleNotes from "../features/trikin/bubbleNotesSlice";
import bubble from "../features/trikin/bubbleSlice";
import { reducer as readBubble } from "../features/trikin/readBubbleSlice";
import veterinarian from "../features/veterinarianSlice";

import resultUpload from "../features/resultUploadSlice";

const rootReducer = combineReducers({
  agrilab,
  registration,
  notifications,
  check,
  dispose,
  bubble,
  readBubble,
  veterinarian,
  bubbleNotes,
  pause,
  preregistration,
  godInvoice,
  resultUpload,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
