import * as Sentry from "@sentry/browser";
import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
} from "react";
import { useLocalStorage } from "usehooks-ts";
import { useValidateToken } from "../hooks/authHooks";
import type { User } from "../models/user";

type UserContextType = {
  currentUser: User | undefined;
  isValidating: boolean;
  username: string;
};

export const UserContext = createContext<UserContextType>({
  currentUser: undefined,
  isValidating: false,
  username: "",
});

export const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [username, setUsername] = useLocalStorage<string>("username", "");

  const { data, isLoading: isValidating, isError } = useValidateToken();
  const currentUser = !isError ? data?.user : undefined;

  const setUser = useCallback(
    (user: User | undefined) => {
      if (user) {
        Sentry.setUser({
          username: user.name,
          id: user.id?.toString(),
        });
        setUsername(user.name);
      } else {
        Sentry.setUser(null);
      }
    },
    [setUsername],
  );

  useEffect(() => {
    setUser(currentUser);
  }, [currentUser, setUser]);

  const context = {
    currentUser,
    username,
    isValidating,
  };

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
};
