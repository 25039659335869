// import type { IAnalysis } from "./analysis";
import {
  type AnalysisGroupInstance,
  createAnalysisGroupInstance,
} from "./analysisGroupInstance";
import { type Animal, type AnimalMeta, readAnimalMeta } from "./animal";
import type { GroupResult } from "./groupResult";
import type { Well } from "./plate";
import { type IProduct, extraProductFields } from "./product";
import { type IProductFamily, extraProductFamilyFields } from "./productFamily";

export interface JournalMeta extends AnimalMeta {
  sortOrder?: number;
}
export interface Journal {
  readonly tubeid?: string;
  // readonly flatAnalyses?: IAnalysis[];
  readonly id: number;
  readonly labnumber?: number;

  // readonly status?: string;
  readonly identifier?: string;
  readonly meta?: JournalMeta;
  readonly allMeta?: JournalMeta;
  readonly collectedAt?: string;
  readonly analysisGroupInstances: AnalysisGroupInstance[]; //Registration
  readonly validLabnumber?: boolean; //Registration
}

export interface ResultJournal extends Journal {
  readonly analysisResult?: GroupResult[]; //Result
  readonly wells?: Well[]; //Result
}
export interface RegistrationJournal extends Journal {
  readonly animal?: Animal; //Registration
  readonly animalId?: number; //Registration
}

const defaultJournal: Readonly<RegistrationJournal> = {
  validLabnumber: true,
  id: -1,
  analysisGroupInstances: [],
  meta: { sortOrder: 1 },
  allMeta: {},
  // flatAnalyses: [],
};

export function createJournal<T extends Journal>(journal: T) {
  const analysisGroupInstances = journal.analysisGroupInstances?.map((ag) =>
    createAnalysisGroupInstance(ag),
  );
  return { ...defaultJournal, ...journal, analysisGroupInstances };
}

export function isValid(journal: RegistrationJournal) {
  return journal.validLabnumber && !!journal.labnumber;
}
export function readMeta<K extends keyof JournalMeta>(
  journal: RegistrationJournal,
  key: K,
) {
  if (journal.meta?.[key]) {
    return journal.meta[key];
  }
  if (journal.animal) {
    return readAnimalMeta(journal.animal, key);
  }
  return undefined;
}

export function registrationComplete(
  journal: RegistrationJournal,
  productFamily: IProductFamily | undefined,
  product: IProduct | undefined,
) {
  if (!productFamily || !product) {
    return false;
  }
  const fields = {
    ...extraProductFamilyFields(productFamily),
    ...extraProductFields(product),
  };
  if (fields) {
    for (const fieldKey of Object.keys(fields) ?? []) {
      const field = fields[fieldKey];
      if (field.required && !readMeta(journal, fieldKey)) {
        return false;
      }
    }
  }
  return !!journal.identifier;
}
