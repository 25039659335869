import { camelizeKeys } from "humps";
import { use, useCallback, useEffect, useState } from "react";
import { CableContext } from "../context/cableContext";

export const useCableState = <T>(
  channel: string | undefined,
  initialState: T,
) => {
  const [state, setState] = useState(initialState);
  useCableCallback(channel, setState);
  return state;
};

export const useCableCallback = <T>(
  channel: string | undefined | ActionCable.ChannelNameWithParams,
  callback: (data: T) => void,
) => {
  const cableContext = use(CableContext);
  const internalCallback = useCallback(
    (data: T) => {
      const parsedData = <T>(<unknown>camelizeKeys(data));
      callback(parsedData);
    },
    [callback],
  );
  useEffect(() => {
    if (!channel || !internalCallback || !cableContext) return;

    cableContext.subscribe(channel, internalCallback);
    return () => {
      cableContext.unsubscribe(channel);
    };
  }, [channel, internalCallback, cableContext]);
};
