import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { compareVersions } from "compare-versions";
import {
  type FC,
  type PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from "react";
import appApi from "../apis/appApi";
import { authKeys } from "../hooks/authHooks";
import { useWarning } from "../hooks/notificationHooks";
import type { PingResult } from "../models/pingResult";

type ApplicationContextType = {
  pingResult?: PingResult;
  isPinging?: boolean;
  showRefresh?: boolean;
  pingSuccess: boolean;
  showChanges: boolean;
  setShowChanges: (value: boolean) => void;
  emergencyStop: (stop: boolean, reason?: string) => void;
};
export const ApplicationContext = createContext<ApplicationContextType>({
  setShowChanges: () => {},
  emergencyStop: () => {},
  showChanges: false,
  pingSuccess: false,
});
const PING_INTERVAL = 30000;

export const ApplicationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [prevVersion, setPrevVersion] = useState<string | undefined>();
  const [showRefresh, setShowRefresh] = useState(false);
  const [showChanges, setShowChanges] = useState(false);
  const addWarning = useWarning();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["ping"],
    queryFn: () => appApi.ping(),
    refetchInterval: PING_INTERVAL,
  });

  const queryClient = useQueryClient();
  useEffect(() => {
    if (data) {
      const version = data.semver;
      if (prevVersion && version && compareVersions(version, prevVersion) > 0) {
        setShowRefresh(true);
      }
      setPrevVersion(version);
      if (data.validToken === false) {
        queryClient.invalidateQueries({ queryKey: authKeys.currentUser });
      }
    }
  }, [data, prevVersion, queryClient]);

  const stopEmailMutation = useMutation({
    mutationFn: (reason: string) => appApi.stopEmail(reason),
    onSuccess: () => {
      addWarning("Epostutskick har stoppats");
      queryClient.invalidateQueries({ queryKey: ["ping"] });
    },
  });
  const startEmailMutation = useMutation({
    mutationFn: () => appApi.startEmail(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["ping"] });
    },
  });

  const emergencyStop = (stop: boolean, reason?: string) => {
    if (stop) {
      stopEmailMutation.mutateAsync(reason ?? "");
    } else {
      startEmailMutation.mutateAsync();
    }
  };
  const context = {
    pingResult: data,
    isPinging: isLoading,
    showRefresh,
    pingSuccess: !isError,
    showChanges,
    setShowChanges,
    emergencyStop,
  };
  return (
    <ApplicationContext.Provider value={context}>
      {children}
    </ApplicationContext.Provider>
  );
};
