import {
  type ChannelNameWithParams,
  type Subscription,
  createConsumer,
} from "@rails/actioncable";
import {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { UserContext } from "./userContext";

type CableContextType = {
  cable: ReturnType<typeof createConsumer> | undefined;
  subscribe: <T>(
    channel: string | ChannelNameWithParams,
    callback: (data: T) => void,
  ) => void;
  unsubscribe: (channel: string | ChannelNameWithParams) => void;
};

export const CableContext = createContext<CableContextType | undefined>(
  undefined,
);

export const CableProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [cable, setCable] = useState<ReturnType<typeof createConsumer>>();
  const subscriptionsRef = useRef<Map<string, Subscription>>(new Map());
  const { currentUser } = useContext(UserContext);

  // biome-ignore lint/correctness/useExhaustiveDependencies: don't care about changes in cable state, only currentUser
  useEffect(() => {
    // Only create a connection if there is a signed-in user
    if (currentUser) {
      const newCable = createConsumer(`${import.meta.env.VITE_CABLE_URL}`);
      setCable(newCable);

      return () => {
        newCable.disconnect();
      };
    } else if (cable) {
      // Disconnect if user is logged out and we have an existing connection
      cable.disconnect();
      setCable(undefined);
      subscriptionsRef.current.clear();
    }
    return () => {};
  }, [currentUser]);

  const subscribe = <T,>(
    channel: string | ChannelNameWithParams,
    callback: (data: T) => void,
  ) => {
    if (!cable) return;

    const channelKey =
      typeof channel === "string" ? channel : JSON.stringify(channel);
    if (subscriptionsRef.current.has(channelKey)) return;

    const subscription = cable.subscriptions.create(channel, {
      connected: () => console.log("Connected to cable"),
      received: (data: T) => callback(data),
      disconnected: () => console.log("Disconnected from cable"),
    });

    subscriptionsRef.current.set(channelKey, subscription);
  };

  const unsubscribe = (channel: string | ChannelNameWithParams) => {
    const channelKey =
      typeof channel === "string" ? channel : JSON.stringify(channel);
    const subscription = subscriptionsRef.current.get(channelKey);
    if (subscription) {
      subscription.unsubscribe();
      subscriptionsRef.current.delete(channelKey);
    }
  };

  return (
    <CableContext.Provider value={{ cable, subscribe, unsubscribe }}>
      {children}
    </CableContext.Provider>
  );
};
