import { Box, CircularProgress, CssBaseline } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Settings } from "luxon";
import * as React from "react";
import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { ErrorBoundary, ErrorElement } from "./components/app/errorBoundary";
import { MainLayout } from "./components/app/mainLayout";
import { ApplicationProvider } from "./context/applicationContext";
import { CableProvider } from "./context/cableContext";
import { UserProvider } from "./context/userContext";
import { browserItems } from "./helpers/navigationHelpers";
import store from "./reducers/singletonStore";
import { useTheme } from "./views/theme";
Settings.defaultLocale = "sv";

const reloadOnError = (event: Event & { payload?: { message: string } }) => {
  if (!event.payload) {
    return;
  }
  console.log(event);
  const retried = sessionStorage.getItem(`errRetries${event.payload.message}`);
  if (retried) {
    return;
  }
  sessionStorage.setItem(`errRetries${event.payload.message}`, "1");
  window.location.reload();
};

window.addEventListener("vite:preloadError", (event) => {
  reloadOnError(event);
});

const queryClient = new QueryClient({});

const Main: React.FC = () => {
  const theme = useTheme();
  return (
    <React.StrictMode>
      <ErrorBoundary>
        <Provider store={store}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <LocalizationProvider
                dateAdapter={AdapterLuxon}
                adapterLocale="sv"
              >
                <ErrorBoundary>
                  <QueryClientProvider client={queryClient}>
                    <UserProvider>
                      <CableProvider>
                        <ApplicationProvider>
                          <Box
                            sx={(theme) => ({
                              display: "flex",
                              flexGrow: 1,
                              backgroundColor: theme.palette.grey[900],
                              height: "100vh",
                              overflow: "auto",
                              ...theme.applyStyles("light", {
                                backgroundColor: theme.palette.grey[100],
                              }),
                            })}
                          >
                            <Suspense fallback={<CircularProgress />}>
                              <CssBaseline enableColorScheme />
                              <RouterProvider
                                router={router}
                                future={{ v7_startTransition: true }}
                              />
                            </Suspense>
                          </Box>
                        </ApplicationProvider>
                      </CableProvider>
                    </UserProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                  </QueryClientProvider>
                </ErrorBoundary>
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </Provider>
      </ErrorBoundary>
    </React.StrictMode>
  );
};

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV6(createBrowserRouter);
const router = sentryCreateBrowserRouter([
  {
    element: <MainLayout />,
    errorElement: <ErrorElement />,
    children: browserItems().concat({
      path: "*",
      element: <Navigate to="/t/registration" replace />,
    }),
  },
]);

Sentry.init({
  dsn: "https://a954050c85994e8290ee1a0f597e45e2@o113422.ingest.sentry.io/249795",
  environment: import.meta.env.DEV ? "development" : "production",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: import.meta.env.DEV ? 0 : 0.2,
});

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(<Main />);
