import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext, useMemo } from "react";
import authApi from "../apis/authApi";
import { UserContext } from "../context/userContext";
import { hasPermission } from "../helpers/authHelpers";
import type { User } from "../models/user";
import { useCableCallback } from "./cableHooks";

export const useCurrentUser = () => {
  const queryClient = useQueryClient();
  const { currentUser } = useContext(UserContext);
  const handleUserUpdate = useCallback(
    ({ user }: { user: User }) => {
      if (currentUser?.id === user.id) {
        queryClient.invalidateQueries({ queryKey: authKeys.currentUser });
      }
    },
    [currentUser?.id, queryClient],
  );
  const channel = useMemo(
    () =>
      currentUser?.id
        ? { channel: "UserChannel", id: currentUser.id }
        : undefined,
    [currentUser?.id],
  );
  useCableCallback(channel, handleUserUpdate);
  return currentUser;
};

export const useAuthenticated = () => {
  const authenticated = !!useCurrentUser();
  return authenticated;
};

export const usePermission = (permission: string) => {
  const permissions = usePermissions();
  return hasPermission(permission, permissions);
};

export const useHasPermission = () => {
  const permissions = usePermissions();
  return (permission: string | undefined) =>
    hasPermission(permission, permissions);
};

export const useHasPermissions = () => {
  const userPermissions = usePermissions();
  return (permissions: string[]) =>
    permissions.every((permission) =>
      hasPermission(permission, userPermissions),
    );
};

export const usePermissions = () => {
  return useCurrentUser()?.permissions ?? {};
};

export const authKeys = {
  currentUser: ["auth", "currentUser"] as const,
};

export function useValidateToken() {
  return useQuery({
    queryKey: authKeys.currentUser,
    queryFn: () => authApi.currentUser(),
    retry: false,
    staleTime: 60 * 1000, // Consider token valid for 1 minutes
  });
}

export function useLogin() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (credentials: { username: string; password: string }) =>
      authApi.login(credentials.username, credentials.password),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: authKeys.currentUser });
      queryClient.invalidateQueries({ queryKey: ["ping"] });
    },
  });
}

export function useLogout() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => authApi.logout(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: authKeys.currentUser });
    },
  });
}
