export function getErrorMessage(error: unknown) {
  if (error instanceof Error) {
    return parseError(error.message);
  }
  return parseError(String(error));
}

function parseError(error: string) {
  try {
    const parsed = JSON.parse(error);
    return parsed.text || parsed.message || error;
  } catch {
    return error;
  }
}

export function getExceptionMessage(error: unknown) {
  type ErrorResponse = {
    response?: {
      data?: {
        exception?: string;
      };
    };
  };

  const errorResponse = (error as ErrorResponse)?.response?.data || {};
  const errorMessage = errorResponse.exception || getErrorMessage(error);
  return errorMessage;
}
