import type { PayloadAction } from "@reduxjs/toolkit";
import { useCallback } from "react";
import {
  addError,
  addInfo,
  addSuccess,
  addWarning,
  closeAll,
} from "../features/notificationSlice";
import type { AlertSeverity, AlertStatus } from "../models/alert";
import { useAppDispatch } from "./storeHooks";

export const useSuccess = () => {
  const dispatch = useAppDispatch();
  return (message: string) => dispatch(addSuccess(message));
};

export const useWarning = () => {
  const dispatch = useAppDispatch();
  return (message: string) => dispatch(addWarning(message));
};

export const useError = () => {
  const dispatch = useAppDispatch();
  return useCallback(
    (message: string) => dispatch(addError(message)),
    [dispatch],
  );
};

export const useInfo = () => {
  const dispatch = useAppDispatch();
  return (message: string) => dispatch(addInfo(message));
};

export const useClearAll = () => {
  const dispatch = useAppDispatch();
  return () => dispatch(closeAll());
};

export const useAddAlert = () => {
  const dispatch = useAppDispatch();
  return (message: string, status: AlertStatus | AlertSeverity | undefined) => {
    let action: PayloadAction<string> | undefined;
    switch (status) {
      case "success":
        action = addSuccess(message);
        break;
      case "warning":
        action = addWarning(message);
        break;
      case "danger":
      case "error":
        action = addError(message);
        break;
      default:
        action = addInfo(message);
        break;
    }
    if (action) {
      dispatch(action);
    }
  };
};
