import {
  type Analysis,
  type IAnalysis,
  analysisSorter,
  initialize,
} from "../models/analysis";
import type { ValidationResult } from "../models/framework/validationResult";
import * as Formulas from "./analysisFormulas";

export function calculateAnalyses(
  rawAnalyses: readonly Analysis[],
  otherAnalyses: readonly Analysis[],
  initializeEmpty = false,
) {
  let analyses = rawAnalyses.map((a) =>
    a.raw == null && initializeEmpty ? initialize(a) : a,
  );
  const missingDependencies = getMissingDependencies(analyses);

  if (missingDependencies.length > 0) {
    for (const dep of missingDependencies) {
      analyses.unshift(
        initialize({
          meta: {
            formulaName: dep,
            formula: dep as Formulas.Formulas,
          },
          productMeta: analyses[0].productMeta,
          id: 0,
          type: "",
          name: "",
          details: undefined,
          code: "",
          default: null,
          typeId: 0,
          extra: false,
        }),
      );
    }
  }

  const calculated: Analysis[] = [];
  const toCalculate: Analysis[] = analyses.slice();

  const maxIterations = toCalculate.length * 2;
  let iterations = 0;

  while (toCalculate.length > 0) {
    if (iterations++ > maxIterations) {
      calculated.push(...toCalculate);
      break;
    }

    const analysis = toCalculate.shift()!;
    const formula = Formulas.getFormula(analysis);

    const dependencies = formula ? formula.calculatedDependencies : [];
    const dependenciesReady = dependencies.every((dep) =>
      calculated.find((a) => a.meta?.formulaName === dep),
    );
    if (dependenciesReady || formula?.formulaOptions?.optionalDependencies) {
      const { value, ...details } = calculate(
        analysis,
        [...calculated, ...toCalculate],
        otherAnalyses,
      );
      calculated.push({
        ...analysis,
        calc: value,
        details: { ...analysis.details, ...details },
      });
    } else {
      toCalculate.push(analysis);
    }
  }
  analyses = calculated.filter((a) => a.code !== "");
  analyses.sort(analysisSorter);

  return analyses;
}

function getMissingDependencies(analyses: IAnalysis[]) {
  const allDependencies = analyses.flatMap((a) => {
    const formula = Formulas.getFormula(a);
    return !formula?.formulaOptions?.optionalDependencies
      ? [
          ...(formula?.dependencies ?? []),
          ...(formula?.calculatedDependencies ?? []),
        ]
      : [];
  });
  const missingDependencies = Array.from(
    new Set(
      allDependencies.filter(
        (dep) => !analyses.find((a) => a.meta?.formulaName === dep),
      ),
    ),
  );
  return missingDependencies;
}

function calculate(
  analysis: Analysis,
  analyses: readonly Analysis[],
  otherAnalyses: readonly Analysis[],
) {
  if (analysis.meta?.formula) {
    return Formulas.executeFormula(
      analysis.meta.formula,
      analysis,
      analyses,
      otherAnalyses,
    );
  } else if (analysis.type === "percent") {
    return Formulas.executeFormula(
      "percent",
      analysis,
      analyses,
      otherAnalyses,
    );
  } else {
    return Formulas.executeFormula("epg", analysis, analyses, otherAnalyses);
  }
}

export function radioValue(analysis: Analysis) {
  return analysis.raw == null ? null : analysis.raw ? "1" : "0";
}

export function validate(
  analysis: Analysis,
  analyses: readonly Analysis[],
): ValidationResult<Analysis> {
  let valid = true;
  let validationMessage: string | undefined;
  if (analysis.max && analysis.raw) {
    if (analysis.raw > analysis.max) {
      valid = false;
      validationMessage = "Observera! Högt äggantal";
    }
  }
  if (analysis.meta?.warningLimits && analysis.raw) {
    if (
      analysis.raw < +analysis.meta.warningLimits[0] ||
      analysis.raw > +analysis.meta.warningLimits[1]
    ) {
      valid = false;
      validationMessage = `Utanför gränsvärden [${analysis.meta.warningLimits[0]} - ${analysis.meta.warningLimits[1]}]`;
    }
  }
  if (analysis.type === "percent" || analysis.meta?.formula === "percent") {
    const percent = analysis;
    if (percent.calc !== undefined && percent.calc > 100) {
      valid = false;
      validationMessage = ">100%";
    }
    if (analysis.percentOf) {
      const total = analyses
        .filter((a) => a.percentOf === analysis.percentOf)
        .map((a) => (a ? a?.calc || 0 : 0))
        .reduce<number>((a, b) => a + b, 0);
      if (total > 100) {
        valid = false;
        validationMessage = ">100% totalt";
      }
    }
  }
  if (
    analysis.meta?.lowerlimit &&
    analysis.raw &&
    analysis.raw < analysis.meta.lowerlimit
  ) {
    valid = false;
    validationMessage = "Utanför giltigt intervall";
  }

  return { valid, validationMessage };
}
